.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal-main {
  position: fixed;
  background: white;
  max-width: 600px;
  height: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-close {
  font-size: 16pt;
  margin-right: 5px;
  cursor: pointer;
}
.modal-body {
  height: 90%;
  overflow-y: auto;
}
