#chat-message-input {
  margin-right: -5px;
}
.chat-messages {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 138px);
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: all 0.5s;
}
.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
}
.chat-message-left {
  margin-right: auto;
}
.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}

/* typing bubble */
.chat-bubble {
  background-color: #fff3cd;
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1s infinite ease-in-out;
  background-color: #fff0c0;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #ffc003;
  }
  28% {
    transform: translateY(-7px);
    background-color: #e4bf52;
  }
  44% {
    transform: translateY(0px);
    background-color: #dac587;
  }
}
