#authFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
#authForm {
  width: 350px;
  padding: 15px 25px;
  box-shadow: 0px 0px 5px #c5c4c5;
  margin: 0px 15px;
}
#authTitle {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 25px;
  color: #999999;
}
.authFieldContainer {
  margin: 10px 0px;
}
.authField {
  width: 100%;
  border: 1px solid #c5c4c5;
  padding: 10px 5px;
  border-radius: 5px;
}
.authField:focus {
  outline: none;
}
.requiredFieldError {
  margin: 0px;
  color: rgb(252, 41, 41);
  font-size: 9pt;
}
#authFormFooter {
  text-align: center;
  color: #898989;
}
#authFormFooter a {
  color: #ffc107;
}
#loginMessage {
  text-align: center;
  padding: 0.5rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
